<!-- 综合搜索 -->
<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>

        <div class="v2-g-w1200" style="position: relative;">
          <ProductList
            :list="list"
            :pageInfo="pageInfo"
            :isGlobalSearch="true"
            :isShowEmptyState="false"
            @getdata="getDataByPage"
            @getGoodsList="getData"
          />

          <div class="nodata" style="position: relative; margin-top: 100px;" v-if="list.length == 0">
            <img src="@/assets-v2/images/nodata.png" />
            <span style="position: absolute; left: 50%; top: 120px; transform: translate(-50%); color: #666;">
              {{ isShowEmptyState ? '马上来啦！' : '暂无相关商品' }}
            </span>
          </div>

          <div class="btn2" @click="$router.push({ name: 'v2-buy' })" v-if="btnShow">
            <button>商品建议</button>
          </div>
        </div>

        <PageBottom></PageBottom>
    </div>
</template>

<script>
import ProductList from "@/components-v2/product/product-list";
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import { kv } from "../../mixin/kv"
import { purchaseQuantityProcessing } from '@/utils'

export default {
    mixins: [kv],
    data() {
        return {
            btnShow: false,//我要求购显示隐藏
            list: [],
            pageInfo: {
                total: 0,
                pageNum: 1,
            },
            queryParam: {
              pageSize: 20,
              page: 1,
              sortType: 1,  // 排序规则 1.综合 2.销量升序 3.销量降序 4.价格升序 5.价格降序 6.活动
            },
            isShowEmptyState: true
        };
    },
    components: {
        ProductList,
        PageTop,
        PageTop1,
        PageBottom
    },
    created() {
        this.saveSearchWord();
        this.kv_visit(12); //页面访问埋点
        this.getData();
    },
    watch: {
        '$route.params': function () {
            this.pageInfo.page = 1;
            this.kv_visit(12); //页面访问埋点
            //保存搜索记录
            this.saveSearchWord();
            this.getData();
        }
    },
    methods: {
        //保存搜索关键词
        async saveSearchWord() {
            const url = '/pjyy-deepexi-product-center/api/v1/product/user/search/save';
            let param = {
                "keyWord": this.$route.params.q
            }
            this.$getHttpClient({ useSpinner: false }).post(url, param);
        },
        async getData() {
            this.isShowEmptyState = true;
            let q = this.$route.params.q;
            if (q == undefined || q == '' || q == null) {
                return;
            }
            this.queryParam.param = q;
            const url = "/pjyy-deepexi-product-center/api/v4/product/PcProductCommon/search";
            const result = await this.$getHttpClient().post(url, this.queryParam);
            let { code, data } = result.data;
            if (code == 200) {
                this.list = purchaseQuantityProcessing(data.list || []);
                this.btnShow = this.list.length == 0
                this.pageInfo.total = data.total;
                this.pageInfo.pageNum = data.pageNum;
                this.pageInfo.pageSize = data.pageSize;
                this.isShowEmptyState = false;
            }
        },
        getDataByPage(data) {
            this.queryParam.page = data.pageNum;
            this.queryParam.sortType = data.sortType;
            this.getData();
        }
    }
}
</script>

<style scoped lang="less">
.btn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background: #3875f6;
  color: #fff;
  cursor: pointer;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  button {
    font-size: 1.25rem;
    color: #fff;
    background: #3875f6;
    border: none;
    margin-left: 5px;
  }

  span {
    font-size: 1.25rem !important;
  }
}
</style>
